"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createModuleLogger = exports.projectLogger = void 0;
const utils_1 = require("@metamask/utils");
Object.defineProperty(exports, "createModuleLogger", {
  enumerable: true,
  get: function () {
    return utils_1.createModuleLogger;
  }
});
exports.projectLogger = (0, utils_1.createProjectLogger)('eth-block-tracker');

{
  "name": "@opengsn/common",
  "version": "3.0.0-beta.6",
  "license": "Apache-2.0",
  "main": "dist/index.js",
  "scripts": {
    "tsc": "tsc",
    "watch-tsc": "tsc -w --preserveWatchOutput",
    "lint": "eslint -f unix .",
    "lint-fix": "yarn lint --fix",
    "rm-dist": "rm -rf tsconfig.tsbuildinfo dist"
  },
  "files": [
    "dist/*",
    "README.md"
  ],
  "publishConfig": {
    "access": "public"
  },
  "dependencies": {
    "@ethereumjs/common": "^2.6.5",
    "@ethereumjs/tx": "^3.2.0",
    "@ethersproject/abi": "^5.7.0",
    "@ethersproject/bignumber": "^5.7.0",
    "@ethersproject/networks": "^5.7.1",
    "@ethersproject/providers": "^5.7.2",
    "@metamask/eth-sig-util": "^4.0.1",
    "@opengsn/contracts": "^3.0.0-beta.6",
    "@types/bn.js": "^5.1.0",
    "@types/semver": "^7.3.4",
    "axios": "^0.21.1",
    "bn.js": "^5.2.0",
    "chalk": "^4.1.2",
    "ethereumjs-util": "^7.1.0",
    "ethers": "^5.7.2",
    "ethjs-unit": "^0.1.6",
    "number-to-bn": "^1.7.0",
    "ow": "^0.28.1",
    "rlp": "^2.2.6",
    "semver": "^7.3.2"
  },
  "gitHead": "e02767b4741a0ef1ec105548c4ce6c0c92c23b2b"
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransactionType = void 0;
var TransactionType;
(function (TransactionType) {
  TransactionType[TransactionType["LEGACY"] = 0] = "LEGACY";
  TransactionType[TransactionType["TYPE_TWO"] = 2] = "TYPE_TWO";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));

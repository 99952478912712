"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpClient = void 0;
const Utils_1 = require("./Utils");
class HttpClient {
  constructor(httpWrapper, logger) {
    this.httpWrapper = httpWrapper;
    this.logger = logger;
  }
  async getPingResponse(relayUrl, paymaster) {
    const url = new URL('getaddr', (0, Utils_1.appendSlashTrim)(relayUrl));
    if (paymaster != null) {
      url.searchParams.set('paymaster', paymaster);
    }
    const pingResponse = await this.httpWrapper.sendPromise(url);
    this.logger.info(`pingResponse: ${JSON.stringify(pingResponse)}`);
    if (pingResponse == null) {
      throw new Error('Relay responded without a body');
    }
    return pingResponse;
  }
  async relayTransaction(relayUrl, request) {
    const url = new URL('relay', (0, Utils_1.appendSlashTrim)(relayUrl));
    const {
      signedTx,
      nonceGapFilled,
      error
    } = await this.httpWrapper.sendPromise(url, request);
    this.logger.info(`relayTransaction response: ${signedTx}, error: ${error}`);
    if (error != null) {
      throw new Error(`Got error response from relay: ${error}`);
    }
    if (signedTx == null) {
      throw new Error('body.signedTx field missing.');
    }
    return {
      signedTx,
      nonceGapFilled
    };
  }
  async auditTransaction(relayUrl, signedTx) {
    const url = new URL('audit', (0, Utils_1.appendSlashTrim)(relayUrl));
    const auditRequest = {
      signedTx
    };
    const auditResponse = await this.httpWrapper.sendPromise(url, auditRequest);
    this.logger.info(`auditTransaction response: ${JSON.stringify(auditResponse)}`);
    return auditResponse;
  }
  async getNetworkConfiguration(clientDefaultConfigUrl) {
    const configResponse = await this.httpWrapper.sendPromise(new URL(clientDefaultConfigUrl));
    this.logger.info(`Config response: ${JSON.stringify(configResponse)}`);
    return configResponse;
  }
  async getVerifyingPaymasterAddress(verifierServerUrl, chainId) {
    const url = new URL('getPaymasterAddress', (0, Utils_1.appendSlashTrim)(verifierServerUrl));
    url.searchParams.set('chainId', chainId.toString());
    const {
      paymasterAddress
    } = await this.httpWrapper.sendPromise(url);
    this.logger.info(`VerifyingPaymaster address: ${JSON.stringify(paymasterAddress)}`);
    return paymasterAddress;
  }
}
exports.HttpClient = HttpClient;

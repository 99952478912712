"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StringPredicate = void 0;
const is_1 = require("@sindresorhus/is");
const valiDate = require("vali-date");
const predicate_1 = require("./predicate");
class StringPredicate extends predicate_1.Predicate {
  /**
  @hidden
  */
  constructor(options) {
    super('string', options);
  }
  /**
  Test a string to have a specific length.
   @param length - The length of the string.
  */
  length(length) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have length \`${length}\`, got \`${value}\``,
      validator: value => value.length === length
    });
  }
  /**
  Test a string to have a minimum length.
   @param length - The minimum length of the string.
  */
  minLength(length) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have a minimum length of \`${length}\`, got \`${value}\``,
      validator: value => value.length >= length,
      negatedMessage: (value, label) => `Expected ${label} to have a maximum length of \`${length - 1}\`, got \`${value}\``
    });
  }
  /**
  Test a string to have a maximum length.
   @param length - The maximum length of the string.
  */
  maxLength(length) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have a maximum length of \`${length}\`, got \`${value}\``,
      validator: value => value.length <= length,
      negatedMessage: (value, label) => `Expected ${label} to have a minimum length of \`${length + 1}\`, got \`${value}\``
    });
  }
  /**
  Test a string against a regular expression.
   @param regex - The regular expression to match the value with.
  */
  matches(regex) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to match \`${regex}\`, got \`${value}\``,
      validator: value => regex.test(value)
    });
  }
  /**
  Test a string to start with a specific value.
   @param searchString - The value that should be the start of the string.
  */
  startsWith(searchString) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to start with \`${searchString}\`, got \`${value}\``,
      validator: value => value.startsWith(searchString)
    });
  }
  /**
  Test a string to end with a specific value.
   @param searchString - The value that should be the end of the string.
  */
  endsWith(searchString) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to end with \`${searchString}\`, got \`${value}\``,
      validator: value => value.endsWith(searchString)
    });
  }
  /**
  Test a string to include a specific value.
   @param searchString - The value that should be included in the string.
  */
  includes(searchString) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to include \`${searchString}\`, got \`${value}\``,
      validator: value => value.includes(searchString)
    });
  }
  /**
  Test if the string is an element of the provided list.
   @param list - List of possible values.
  */
  oneOf(list) {
    return this.addValidator({
      message: (value, label) => {
        let printedList = JSON.stringify(list);
        if (list.length > 10) {
          const overflow = list.length - 10;
          printedList = JSON.stringify(list.slice(0, 10)).replace(/]$/, `,…+${overflow} more]`);
        }
        return `Expected ${label} to be one of \`${printedList}\`, got \`${value}\``;
      },
      validator: value => list.includes(value)
    });
  }
  /**
  Test a string to be empty.
  */
  get empty() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be empty, got \`${value}\``,
      validator: value => value === ''
    });
  }
  /**
  Test a string to be not empty.
  */
  get nonEmpty() {
    return this.addValidator({
      message: (_, label) => `Expected ${label} to not be empty`,
      validator: value => value !== ''
    });
  }
  /**
  Test a string to be equal to a specified string.
   @param expected - Expected value to match.
  */
  equals(expected) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be equal to \`${expected}\`, got \`${value}\``,
      validator: value => value === expected
    });
  }
  /**
  Test a string to be alphanumeric.
  */
  get alphanumeric() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be alphanumeric, got \`${value}\``,
      validator: value => /^[a-z\d]+$/i.test(value)
    });
  }
  /**
  Test a string to be alphabetical.
  */
  get alphabetical() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be alphabetical, got \`${value}\``,
      validator: value => /^[a-z]+$/gi.test(value)
    });
  }
  /**
  Test a string to be numeric.
  */
  get numeric() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be numeric, got \`${value}\``,
      validator: value => /^[+-]?\d+$/i.test(value)
    });
  }
  /**
  Test a string to be a valid date.
  */
  get date() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be a date, got \`${value}\``,
      validator: valiDate
    });
  }
  /**
  Test a non-empty string to be lowercase. Matching both alphabetical & numbers.
  */
  get lowercase() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be lowercase, got \`${value}\``,
      validator: value => value.trim() !== '' && value === value.toLowerCase()
    });
  }
  /**
  Test a non-empty string to be uppercase. Matching both alphabetical & numbers.
  */
  get uppercase() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be uppercase, got \`${value}\``,
      validator: value => value.trim() !== '' && value === value.toUpperCase()
    });
  }
  /**
  Test a string to be a valid URL.
  */
  get url() {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to be a URL, got \`${value}\``,
      validator: is_1.default.urlString
    });
  }
}
exports.StringPredicate = StringPredicate;
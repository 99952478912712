"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AsyncZeroAddressCalldataGasEstimation = void 0;
const Constants_1 = require("../Constants");
/**
 * In most L2s, the cost of the transaction is dynamic and depends on L1 gas price.
 * This function tries to extract calldata cost by requesting an estimate but setting target to zero address.
 * As our result must be above the Relay Server's estimate, it makes sense to add some slack to the estimate.
 * @param calldata
 * @param environment
 * @param calldataEstimationSlackFactor
 * @param provider
 * @constructor
 */
const AsyncZeroAddressCalldataGasEstimation = async (calldata, environment, calldataEstimationSlackFactor, provider) => {
  const estimateGasCallToZero = await provider.estimateGas({
    to: Constants_1.constants.ZERO_ADDRESS,
    data: calldata
  });
  return parseInt(estimateGasCallToZero.toString()) * calldataEstimationSlackFactor;
};
exports.AsyncZeroAddressCalldataGasEstimation = AsyncZeroAddressCalldataGasEstimation;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateArgumentErrorMessage = void 0;
/**
Generates a complete message from all errors generated by predicates.

@param errors - The errors generated by the predicates.
@param isAny - If this function is called from the any argument.
@hidden
*/
const generateArgumentErrorMessage = (errors, isAny = false) => {
  const message = [];
  const errorArray = [...errors.entries()];
  const anyErrorWithoutOneItemOnly = errorArray.some(([, array]) => array.size !== 1);
  // If only one error "key" is present, enumerate all of those errors only.
  if (errorArray.length === 1) {
    const [, returnedErrors] = errorArray[0];
    if (!isAny && returnedErrors.size === 1) {
      const [errorMessage] = returnedErrors;
      return errorMessage;
    }
    for (const entry of returnedErrors) {
      message.push(`${isAny ? '  - ' : ''}${entry}`);
    }
    return message.join('\n');
  }
  // If every predicate returns just one error, enumerate them as is.
  if (!anyErrorWithoutOneItemOnly) {
    return errorArray.map(([, [item]]) => `  - ${item}`).join('\n');
  }
  // Else, iterate through all the errors and enumerate them.
  for (const [key, value] of errorArray) {
    message.push(`Errors from the "${key}" predicate:`);
    for (const entry of value) {
      message.push(`  - ${entry}`);
    }
  }
  return message.join('\n');
};
exports.generateArgumentErrorMessage = generateArgumentErrorMessage;
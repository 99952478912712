"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assertIsString = exports.assertIsArray = exports.assertIsBuffer = exports.assertIsHexString = void 0;
var internal_1 = require("./internal");
/**
 * Throws if a string is not hex prefixed
 * @param {string} input string to check hex prefix of
 */
var assertIsHexString = function (input) {
  if (!(0, internal_1.isHexString)(input)) {
    var msg = "This method only supports 0x-prefixed hex strings but input was: ".concat(input);
    throw new Error(msg);
  }
};
exports.assertIsHexString = assertIsHexString;
/**
 * Throws if input is not a buffer
 * @param {Buffer} input value to check
 */
var assertIsBuffer = function (input) {
  if (!Buffer.isBuffer(input)) {
    var msg = "This method only supports Buffer but input was: ".concat(input);
    throw new Error(msg);
  }
};
exports.assertIsBuffer = assertIsBuffer;
/**
 * Throws if input is not an array
 * @param {number[]} input value to check
 */
var assertIsArray = function (input) {
  if (!Array.isArray(input)) {
    var msg = "This method only supports number arrays but input was: ".concat(input);
    throw new Error(msg);
  }
};
exports.assertIsArray = assertIsArray;
/**
 * Throws if input is not a string
 * @param {string} input value to check
 */
var assertIsString = function (input) {
  if (typeof input !== 'string') {
    var msg = "This method only supports strings but input was: ".concat(input);
    throw new Error(msg);
  }
};
exports.assertIsString = assertIsString;

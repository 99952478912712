"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultEnvironment = exports.environments = exports.merge = exports.EnvironmentsKeys = void 0;
const Constants_1 = require("../Constants");
var EnvironmentsKeys;
(function (EnvironmentsKeys) {
  EnvironmentsKeys["ethereumMainnet"] = "ethereumMainnet";
  EnvironmentsKeys["arbitrum"] = "arbitrum";
})(EnvironmentsKeys = exports.EnvironmentsKeys || (exports.EnvironmentsKeys = {}));
// deep (3-level) merge of environments
function merge(env1, env2) {
  var _a, _b;
  return Object.assign({}, env1, env2, {
    relayHubConfiguration: Object.assign({}, env1.relayHubConfiguration, env2.relayHubConfiguration),
    penalizerConfiguration: Object.assign({}, env1.penalizerConfiguration, env2.penalizerConfiguration),
    paymasterConfiguration: Object.assign({}, env1.paymasterConfiguration, env2.paymasterConfiguration),
    deploymentConfiguration: Object.assign({}, env1.deploymentConfiguration, env2.deploymentConfiguration, {
      minimumStakePerToken: Object.assign({}, (_a = env1.deploymentConfiguration) === null || _a === void 0 ? void 0 : _a.minimumStakePerToken, (_b = env2.deploymentConfiguration) === null || _b === void 0 ? void 0 : _b.minimumStakePerToken)
    })
  });
}
exports.merge = merge;
/**
 * Maximum unstake delay is defined at around 3 years for the mainnet.
 * This is done to prevent mistakenly setting an unstake delay to millions of years.
 */
const defaultStakeManagerMaxUnstakeDelay = 100000000;
const defaultPenalizerConfiguration = {
  penalizeBlockDelay: 5,
  penalizeBlockExpiration: 60000
};
const defaultRelayHubConfiguration = {
  gasOverhead: 34909,
  postOverhead: 38516,
  gasReserve: 100000,
  maxWorkerCount: 10,
  minimumUnstakeDelay: 15000,
  devAddress: '0xeFEfeFEfeFeFEFEFEfefeFeFefEfEfEfeFEFEFEf',
  devFee: 0,
  // TODO STOPSHIP: DECIDE!
  pctRelayFee: 0,
  baseRelayFee: 0
};
// TODO add as constructor params to paymaster instead of constants
const preRelayedCallGasLimit = 1e5;
const forwarderHubOverhead = 5e4;
const defaultPaymasterConfiguration = {
  forwarderHubOverhead: forwarderHubOverhead,
  preRelayedCallGasLimit: preRelayedCallGasLimit,
  postRelayedCallGasLimit: 11e4,
  acceptanceBudget: preRelayedCallGasLimit + forwarderHubOverhead,
  calldataSizeLimit: 10404
};
const ethereumMainnet = {
  environmentsKey: EnvironmentsKeys.ethereumMainnet,
  calldataEstimationSlackFactor: 1,
  useEstimateGasForCalldataCost: false,
  dataOnChainHandlingGasCostPerByte: 13,
  relayHubConfiguration: defaultRelayHubConfiguration,
  penalizerConfiguration: defaultPenalizerConfiguration,
  paymasterConfiguration: defaultPaymasterConfiguration,
  maxUnstakeDelay: defaultStakeManagerMaxUnstakeDelay,
  stakeBurnAddress: Constants_1.constants.BURN_ADDRESS,
  abandonmentDelay: 31536000,
  escheatmentDelay: 2629746,
  mintxgascost: 21000,
  gtxdatanonzero: 16,
  gtxdatazero: 4,
  getGasPriceFactor: 1,
  nonZeroDevFeeGasOverhead: 5605
};
/* begin Arbitrum-specific Environment */
const arbitrumRelayHubConfigurationOverride = {
  gasOverhead: 1000000,
  postOverhead: 0
};
const arbitrumRelayHubConfiguration = Object.assign({}, defaultRelayHubConfiguration, arbitrumRelayHubConfigurationOverride);
const arbitrum = Object.assign({}, ethereumMainnet, {
  environmentsKey: EnvironmentsKeys.arbitrum,
  calldataEstimationSlackFactor: 1.3,
  useEstimateGasForCalldataCost: true,
  relayHubConfiguration: arbitrumRelayHubConfiguration,
  penalizerConfiguration: defaultPenalizerConfiguration,
  paymasterConfiguration: defaultPaymasterConfiguration,
  maxUnstakeDelay: defaultStakeManagerMaxUnstakeDelay,
  mintxgascost: 0,
  gtxdatanonzero: 0,
  gtxdatazero: 0,
  // there is currently a hard-coded to be 2 at arbitrum:eth.go:43 (commit: 12483cfa17a29e7d68c354c456ebc371b05a6ea2)
  // setting factor to 0.6 instead of 0.5 to allow the transaction to pass in case of moderate gas price increase
  // note that excess will be collected by the Relay Server as an extra profit
  getGasPriceFactor: 0.6
});
/* end Arbitrum-specific Environment */
exports.environments = {
  ethereumMainnet,
  arbitrum
};
exports.defaultEnvironment = exports.environments.ethereumMainnet;

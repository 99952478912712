"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toType = exports.TypeOutput = exports.bnToRlp = exports.bnToUnpaddedBuffer = exports.bnToHex = void 0;
var externals_1 = require("./externals");
var internal_1 = require("./internal");
var bytes_1 = require("./bytes");
/**
 * Convert BN to 0x-prefixed hex string.
 */
function bnToHex(value) {
  return "0x".concat(value.toString(16));
}
exports.bnToHex = bnToHex;
/**
 * Convert value from BN to an unpadded Buffer
 * (useful for RLP transport)
 * @param value value to convert
 */
function bnToUnpaddedBuffer(value) {
  // Using `bn.toArrayLike(Buffer)` instead of `bn.toBuffer()`
  // for compatibility with browserify and similar tools
  return (0, bytes_1.unpadBuffer)(value.toArrayLike(Buffer));
}
exports.bnToUnpaddedBuffer = bnToUnpaddedBuffer;
/**
 * Deprecated alias for {@link bnToUnpaddedBuffer}
 * @deprecated
 */
function bnToRlp(value) {
  return bnToUnpaddedBuffer(value);
}
exports.bnToRlp = bnToRlp;
/**
 * Type output options
 */
var TypeOutput;
(function (TypeOutput) {
  TypeOutput[TypeOutput["Number"] = 0] = "Number";
  TypeOutput[TypeOutput["BN"] = 1] = "BN";
  TypeOutput[TypeOutput["Buffer"] = 2] = "Buffer";
  TypeOutput[TypeOutput["PrefixedHexString"] = 3] = "PrefixedHexString";
})(TypeOutput = exports.TypeOutput || (exports.TypeOutput = {}));
function toType(input, outputType) {
  if (input === null) {
    return null;
  }
  if (input === undefined) {
    return undefined;
  }
  if (typeof input === 'string' && !(0, internal_1.isHexString)(input)) {
    throw new Error("A string must be provided with a 0x-prefix, given: ".concat(input));
  } else if (typeof input === 'number' && !Number.isSafeInteger(input)) {
    throw new Error('The provided number is greater than MAX_SAFE_INTEGER (please use an alternative input type)');
  }
  var output = (0, bytes_1.toBuffer)(input);
  if (outputType === TypeOutput.Buffer) {
    return output;
  } else if (outputType === TypeOutput.BN) {
    return new externals_1.BN(output);
  } else if (outputType === TypeOutput.Number) {
    var bn = new externals_1.BN(output);
    var max = new externals_1.BN(Number.MAX_SAFE_INTEGER.toString());
    if (bn.gt(max)) {
      throw new Error('The provided number is greater than MAX_SAFE_INTEGER (please use an alternative output type)');
    }
    return bn.toNumber();
  } else {
    // outputType === TypeOutput.PrefixedHexString
    return "0x".concat(output.toString('hex'));
  }
}
exports.toType = toType;

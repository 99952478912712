"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notNull = void 0;
function notNull(value) {
  return value !== null && value !== undefined;
}
exports.notNull = notNull;

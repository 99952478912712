"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cloneRelayRequest = void 0;
function cloneRelayRequest(relayRequest) {
  return {
    request: Object.assign({}, relayRequest.request),
    relayData: Object.assign({}, relayRequest.relayData)
  };
}
exports.cloneRelayRequest = cloneRelayRequest;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorPredicate = void 0;
const predicate_1 = require("./predicate");
class ErrorPredicate extends predicate_1.Predicate {
  /**
  @hidden
  */
  constructor(options) {
    super('error', options);
  }
  /**
  Test an error to have a specific name.
   @param expected - Expected name of the Error.
  */
  name(expected) {
    return this.addValidator({
      message: (error, label) => `Expected ${label} to have name \`${expected}\`, got \`${error.name}\``,
      validator: error => error.name === expected
    });
  }
  /**
  Test an error to have a specific message.
   @param expected - Expected message of the Error.
  */
  message(expected) {
    return this.addValidator({
      message: (error, label) => `Expected ${label} message to be \`${expected}\`, got \`${error.message}\``,
      validator: error => error.message === expected
    });
  }
  /**
  Test the error message to include a specific message.
   @param message - Message that should be included in the error.
  */
  messageIncludes(message) {
    return this.addValidator({
      message: (error, label) => `Expected ${label} message to include \`${message}\`, got \`${error.message}\``,
      validator: error => error.message.includes(message)
    });
  }
  /**
  Test the error object to have specific keys.
   @param keys - One or more keys which should be part of the error object.
  */
  hasKeys(...keys) {
    return this.addValidator({
      message: (_, label) => `Expected ${label} message to have keys \`${keys.join('`, `')}\``,
      validator: error => keys.every(key => Object.prototype.hasOwnProperty.call(error, key))
    });
  }
  /**
  Test an error to be of a specific instance type.
   @param instance - The expected instance type of the error.
  */
  instanceOf(instance) {
    return this.addValidator({
      message: (error, label) => `Expected ${label} \`${error.name}\` to be of type \`${instance.name}\``,
      validator: error => error instanceof instance
    });
  }
  /**
  Test an Error to be a TypeError.
  */
  get typeError() {
    return this.instanceOf(TypeError);
  }
  /**
  Test an Error to be an EvalError.
  */
  get evalError() {
    return this.instanceOf(EvalError);
  }
  /**
  Test an Error to be a RangeError.
  */
  get rangeError() {
    return this.instanceOf(RangeError);
  }
  /**
  Test an Error to be a ReferenceError.
  */
  get referenceError() {
    return this.instanceOf(ReferenceError);
  }
  /**
  Test an Error to be a SyntaxError.
  */
  get syntaxError() {
    return this.instanceOf(SyntaxError);
  }
  /**
  Test an Error to be a URIError.
  */
  get uriError() {
    return this.instanceOf(URIError);
  }
}
exports.ErrorPredicate = ErrorPredicate;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createVerifierApprovalDataCallback = exports.DEFAULT_VERIFIER_SERVER_APPROVAL_DATA_LENGTH = exports.DEFAULT_VERIFIER_SERVER_URL = void 0;
const common_1 = require("@opengsn/common");
// TODO: replace with production URL before release
exports.DEFAULT_VERIFIER_SERVER_URL = 'https://staging-api.opengsn.org';
exports.DEFAULT_VERIFIER_SERVER_APPROVAL_DATA_LENGTH = 65;
function createVerifierApprovalDataCallback(httpWrapper, logger, domainSeparatorName, chainId, apiKey, verifierUrl) {
  return async function defaultVerifierApprovalDataCallback(relayRequest, relayRequestId) {
    const approvalRequest = {
      apiKey,
      chainId,
      domainSeparatorName,
      relayRequest,
      relayRequestId
    };
    const signRelayRequestResponse = await httpWrapper.sendPromise(new URL('signRelayRequest', (0, common_1.appendSlashTrim)(verifierUrl)), approvalRequest);
    logger.info(`signRelayRequest response: ${JSON.stringify(signRelayRequestResponse)}`);
    return signRelayRequestResponse.signature;
  };
}
exports.createVerifierApprovalDataCallback = createVerifierApprovalDataCallback;

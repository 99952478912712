"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TruffleContract = exports.Contract = void 0;
const ethers_1 = require("ethers");
const Web3JSUtils_1 = require("./web3js/Web3JSUtils");
function getComponent(key, components) {
  // @ts-ignore
  const component = components[key];
  if (component != null) {
    return component;
  }
  return components.find(it => it.name === key);
}
function retypeItem(abiOutput, ret) {
  if (abiOutput.type == null) {
    return ret;
  }
  if (abiOutput.type.includes('int')) {
    return (0, Web3JSUtils_1.toBN)(ret.toString());
  } else if (abiOutput.type === 'tuple[]') {
    return ret.map(item => retypeItem(Object.assign(Object.assign({}, abiOutput), {
      type: 'tuple'
    }), item));
  } else if (abiOutput.type.includes('tuple') && abiOutput.components != null) {
    const keys = Object.keys(ret);
    const newRet = {};
    for (let i = 0; i < keys.length; i++) {
      const component = getComponent(keys[i], abiOutput.components);
      if (component == null) {
        newRet[keys[i]] = ret[keys[i]];
        continue;
      }
      newRet[keys[i]] = retypeItem(component, ret[keys[i]]);
    }
    return newRet;
  } else {
    return ret;
  }
}
// restore TF type: uint are returned as string in web3, and as BN in TF.
function retype(outputs, ret) {
  if ((outputs === null || outputs === void 0 ? void 0 : outputs.length) === 1) {
    return retypeItem(outputs[0], ret);
  } else {
    const response = {};
    outputs === null || outputs === void 0 ? void 0 : outputs.forEach((value, index) => {
      response[index] = retypeItem(value, ret[index]);
    });
    return response;
  }
}
class Contract {
  constructor(contractName, abi) {
    this.contractName = contractName;
    this.abi = abi;
  }
  createContract(address, signer) {
    const ethersContract = new ethers_1.Contract(address, this.abi);
    return ethersContract.connect(signer !== null && signer !== void 0 ? signer : this.provider);
  }
  // return a contract instance at the given address.
  // UNLIKE TF, we don't do any on-chain check if the contract exist.
  // the application is assumed to call some view function (e.g. version) that implicitly verifies a contract
  // is deployed at that address (and has that view function)
  async at(address) {
    // TODO: this is done to force cache the 'from' address to avoid Ethers making a call to 'eth_accounts' every time
    const signerFromAddress = await this.signer.getAddress();
    const addressAwareSigner = this.provider.getSigner(signerFromAddress);
    const contract = this.createContract(address, addressAwareSigner);
    const obj = {
      address,
      contract,
      async getPastEvents(name, options) {
        // @ts-ignore
        return contract.getPastEvents(name, options).map(e => Object.assign(Object.assign({}, e), {
          args: e.returnValues // TODO: web3 uses strings, Truffle uses BN for numbers
        }));
      }
    };
    this.abi.forEach(m => {
      var _a, _b, _c;
      const methodName = (_a = m.name) !== null && _a !== void 0 ? _a : '';
      const nArgs = (_c = (_b = m.inputs) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
      const isViewFunction = m.stateMutability === 'view' || m.stateMutability === 'pure';
      obj[methodName] = async function () {
        let args = Array.from(arguments);
        let options = {};
        if (args.length === nArgs + 1 && typeof args[args.length - 1] === 'object') {
          options = args[args.length - 1];
          args = args.slice(0, args.length - 1);
        }
        // TODO: this substitution seems redundant - try removing it!
        let methodCall;
        if (!isViewFunction) {
          methodCall = contract.functions[methodName];
          return methodCall(...args, options);
        } else {
          methodCall = contract.callStatic[methodName];
          return methodCall(...args, options).then(res => {
            return retype(m.outputs, res);
          });
        }
        // console.log('===calling', methodName, args)
        // return await methodCall.call(options)
        //   .catch((e: Error) => {
        //     console.log('===ex1', e)
        //     throw e
        //   })
      };
    });
    return obj;
  }
  setProvider(provider, _) {
    this.provider = provider;
    this.signer = provider.getSigner();
  }
}
exports.Contract = Contract;
function TruffleContract({
  contractName,
  abi
}) {
  return new Contract(contractName, abi);
}
exports.TruffleContract = TruffleContract;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypedArrayPredicate = void 0;
const predicate_1 = require("./predicate");
class TypedArrayPredicate extends predicate_1.Predicate {
  /**
  Test a typed array to have a specific byte length.
   @param byteLength - The byte length of the typed array.
  */
  byteLength(byteLength) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have byte length of \`${byteLength}\`, got \`${value.byteLength}\``,
      validator: value => value.byteLength === byteLength
    });
  }
  /**
  Test a typed array to have a minimum byte length.
   @param byteLength - The minimum byte length of the typed array.
  */
  minByteLength(byteLength) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have a minimum byte length of \`${byteLength}\`, got \`${value.byteLength}\``,
      validator: value => value.byteLength >= byteLength,
      negatedMessage: (value, label) => `Expected ${label} to have a maximum byte length of \`${byteLength - 1}\`, got \`${value.byteLength}\``
    });
  }
  /**
  Test a typed array to have a minimum byte length.
   @param length - The minimum byte length of the typed array.
  */
  maxByteLength(byteLength) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have a maximum byte length of \`${byteLength}\`, got \`${value.byteLength}\``,
      validator: value => value.byteLength <= byteLength,
      negatedMessage: (value, label) => `Expected ${label} to have a minimum byte length of \`${byteLength + 1}\`, got \`${value.byteLength}\``
    });
  }
  /**
  Test a typed array to have a specific length.
   @param length - The length of the typed array.
  */
  length(length) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have length \`${length}\`, got \`${value.length}\``,
      validator: value => value.length === length
    });
  }
  /**
  Test a typed array to have a minimum length.
   @param length - The minimum length of the typed array.
  */
  minLength(length) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have a minimum length of \`${length}\`, got \`${value.length}\``,
      validator: value => value.length >= length,
      negatedMessage: (value, label) => `Expected ${label} to have a maximum length of \`${length - 1}\`, got \`${value.length}\``
    });
  }
  /**
  Test a typed array to have a maximum length.
   @param length - The maximum length of the typed array.
  */
  maxLength(length) {
    return this.addValidator({
      message: (value, label) => `Expected ${label} to have a maximum length of \`${length}\`, got \`${value.length}\``,
      validator: value => value.length <= length,
      negatedMessage: (value, label) => `Expected ${label} to have a minimum length of \`${length + 1}\`, got \`${value.length}\``
    });
  }
}
exports.TypedArrayPredicate = TypedArrayPredicate;
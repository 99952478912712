"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wrapSigner = exports.wrapContract = void 0;
const ethers_1 = require("ethers");
const RelayProvider_1 = require("./RelayProvider");
async function wrapContract(contract, config, overrideDependencies) {
  const signer = await wrapSigner(contract.signer, config, overrideDependencies);
  return contract.connect(signer);
}
exports.wrapContract = wrapContract;
async function wrapSigner(signer, config, overrideDependencies) {
  const provider = signer.provider;
  if (provider == null) {
    throw new Error('GSN requires a Signer instance with a provider to wrap it');
  }
  const input = {
    // TODO: we don't use anything JsonRpcProvider - specific, but 'getSigner' is not defined on base provider
    provider: provider,
    config,
    overrideDependencies
  };
  // types have a very small conflict about whether "jsonrpc" field is actually required so not worth wrapping again
  // @ts-ignore
  const gsnProvider = await RelayProvider_1.RelayProvider.newProvider(input).init();
  const ethersProvider = new ethers_1.providers.Web3Provider(gsnProvider);
  const address = await signer.getAddress();
  return ethersProvider.getSigner(address);
}
exports.wrapSigner = wrapSigner;

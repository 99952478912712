"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GsnRequestType = exports.TypedRequestData = exports.getDomainSeparatorHash = exports.getDomainSeparator = exports.GsnDomainSeparatorType = exports.EIP712DomainTypeWithoutVersion = exports.EIP712DomainType = void 0;
const ethereumjs_util_1 = require("ethereumjs-util");
const eth_sig_util_1 = require("@metamask/eth-sig-util");
exports.EIP712DomainType = [{
  name: 'name',
  type: 'string'
}, {
  name: 'version',
  type: 'string'
}, {
  name: 'chainId',
  type: 'uint256'
}, {
  name: 'verifyingContract',
  type: 'address'
}];
exports.EIP712DomainTypeWithoutVersion = [{
  name: 'name',
  type: 'string'
}, {
  name: 'chainId',
  type: 'uint256'
}, {
  name: 'verifyingContract',
  type: 'address'
}];
const RelayDataType = [{
  name: 'maxFeePerGas',
  type: 'uint256'
}, {
  name: 'maxPriorityFeePerGas',
  type: 'uint256'
}, {
  name: 'transactionCalldataGasUsed',
  type: 'uint256'
}, {
  name: 'relayWorker',
  type: 'address'
}, {
  name: 'paymaster',
  type: 'address'
}, {
  name: 'forwarder',
  type: 'address'
}, {
  name: 'paymasterData',
  type: 'bytes'
}, {
  name: 'clientId',
  type: 'uint256'
}];
const ForwardRequestType = [{
  name: 'from',
  type: 'address'
}, {
  name: 'to',
  type: 'address'
}, {
  name: 'value',
  type: 'uint256'
}, {
  name: 'gas',
  type: 'uint256'
}, {
  name: 'nonce',
  type: 'uint256'
}, {
  name: 'data',
  type: 'bytes'
}, {
  name: 'validUntilTime',
  type: 'uint256'
}];
const RelayRequestType = [...ForwardRequestType, {
  name: 'relayData',
  type: 'RelayData'
}];
// use these values in registerDomainSeparator
exports.GsnDomainSeparatorType = {
  prefix: 'string name,string version',
  version: '3'
};
function getDomainSeparator(name, verifier, chainId) {
  return {
    name,
    version: exports.GsnDomainSeparatorType.version,
    chainId: chainId,
    verifyingContract: verifier
  };
}
exports.getDomainSeparator = getDomainSeparator;
function getDomainSeparatorHash(name, verifier, chainId) {
  return (0, ethereumjs_util_1.bufferToHex)(eth_sig_util_1.TypedDataUtils.hashStruct('EIP712Domain', getDomainSeparator(name, verifier, chainId), {
    EIP712Domain: exports.EIP712DomainType
  }, eth_sig_util_1.SignTypedDataVersion.V4));
}
exports.getDomainSeparatorHash = getDomainSeparatorHash;
class TypedRequestData {
  constructor(name, chainId, verifier, relayRequest) {
    this.types = {
      EIP712Domain: exports.EIP712DomainType,
      RelayRequest: RelayRequestType,
      RelayData: RelayDataType
    };
    this.domain = getDomainSeparator(name, verifier, chainId);
    this.primaryType = 'RelayRequest';
    // in the signature, all "request" fields are flattened out at the top structure.
    // other params are inside "relayData" sub-type
    this.message = Object.assign(Object.assign({}, relayRequest.request), {
      relayData: relayRequest.relayData
    });
  }
}
exports.TypedRequestData = TypedRequestData;
exports.GsnRequestType = {
  typeName: 'RelayRequest',
  typeSuffix: 'RelayData relayData)RelayData(uint256 maxFeePerGas,uint256 maxPriorityFeePerGas,uint256 transactionCalldataGasUsed,address relayWorker,address paymaster,address forwarder,bytes paymasterData,uint256 clientId)'
};

"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isHexString = exports.getKeys = exports.fromAscii = exports.fromUtf8 = exports.toAscii = exports.arrayContainsArray = exports.getBinarySize = exports.padToEven = exports.stripHexPrefix = exports.isHexPrefixed = void 0;
/**
 * Constants
 */
__exportStar(require("./constants"), exports);
/**
 * Account class and helper functions
 */
__exportStar(require("./account"), exports);
/**
 * Address type
 */
__exportStar(require("./address"), exports);
/**
 * Hash functions
 */
__exportStar(require("./hash"), exports);
/**
 * ECDSA signature
 */
__exportStar(require("./signature"), exports);
/**
 * Utilities for manipulating Buffers, byte arrays, etc.
 */
__exportStar(require("./bytes"), exports);
/**
 * Function for definining properties on an object
 */
__exportStar(require("./object"), exports);
/**
 * External exports (BN, rlp)
 */
__exportStar(require("./externals"), exports);
/**
 * Helpful TypeScript types
 */
__exportStar(require("./types"), exports);
/**
 * Export ethjs-util methods
 */
var internal_1 = require("./internal");
Object.defineProperty(exports, "isHexPrefixed", {
  enumerable: true,
  get: function () {
    return internal_1.isHexPrefixed;
  }
});
Object.defineProperty(exports, "stripHexPrefix", {
  enumerable: true,
  get: function () {
    return internal_1.stripHexPrefix;
  }
});
Object.defineProperty(exports, "padToEven", {
  enumerable: true,
  get: function () {
    return internal_1.padToEven;
  }
});
Object.defineProperty(exports, "getBinarySize", {
  enumerable: true,
  get: function () {
    return internal_1.getBinarySize;
  }
});
Object.defineProperty(exports, "arrayContainsArray", {
  enumerable: true,
  get: function () {
    return internal_1.arrayContainsArray;
  }
});
Object.defineProperty(exports, "toAscii", {
  enumerable: true,
  get: function () {
    return internal_1.toAscii;
  }
});
Object.defineProperty(exports, "fromUtf8", {
  enumerable: true,
  get: function () {
    return internal_1.fromUtf8;
  }
});
Object.defineProperty(exports, "fromAscii", {
  enumerable: true,
  get: function () {
    return internal_1.fromAscii;
  }
});
Object.defineProperty(exports, "getKeys", {
  enumerable: true,
  get: function () {
    return internal_1.getKeys;
  }
});
Object.defineProperty(exports, "isHexString", {
  enumerable: true,
  get: function () {
    return internal_1.isHexString;
  }
});

import { IEvents as e } from "@walletconnect/events";
import c, { EventEmitter as i } from "events";
class n extends e {
  constructor(s) {
    super(), this.opts = s, this.protocol = "wc", this.version = 2;
  }
}
class l {
  constructor(s, t, o) {
    this.core = s, this.logger = t;
  }
}
class h extends e {
  constructor(s, t) {
    super(), this.core = s, this.logger = t, this.records = new Map();
  }
}
class a {
  constructor(s, t) {
    this.logger = s, this.core = t;
  }
}
class u extends e {
  constructor(s, t) {
    super(), this.relayer = s, this.logger = t;
  }
}
class g extends e {
  constructor(s) {
    super();
  }
}
class p {
  constructor(s, t, o, M) {
    this.core = s, this.logger = t, this.name = o;
  }
}
class I {
  constructor() {
    this.map = new Map();
  }
}
class d extends e {
  constructor(s, t) {
    super(), this.relayer = s, this.logger = t;
  }
}
class x {
  constructor(s, t) {
    this.core = s, this.logger = t;
  }
}
class E extends e {
  constructor(s, t) {
    super(), this.core = s, this.logger = t;
  }
}
class m {
  constructor(s, t) {
    this.logger = s, this.core = t;
  }
}
class y {
  constructor(s, t) {
    this.projectId = s, this.logger = t;
  }
}
class v {
  constructor(s, t) {
    this.projectId = s, this.logger = t;
  }
}
class C extends c {
  constructor() {
    super();
  }
}
class b {
  constructor(s) {
    this.opts = s, this.protocol = "wc", this.version = 2;
  }
}
class S extends i {
  constructor() {
    super();
  }
}
class w {
  constructor(s) {
    this.client = s;
  }
}
export { n as ICore, l as ICrypto, v as IEchoClient, w as IEngine, S as IEngineEvents, E as IExpirer, h as IJsonRpcHistory, x as IKeyChain, a as IMessageTracker, m as IPairing, u as IPublisher, g as IRelayer, b as ISignClient, C as ISignClientEvents, p as IStore, d as ISubscriber, I as ISubscriberTopicMap, y as IVerify };

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AmountRequired = void 0;
const Utils_1 = require("./Utils");
const Constants_1 = require("./Constants");
const Web3JSUtils_1 = require("./web3js/Web3JSUtils");
const ether = {
  tokenAddress: Constants_1.constants.ZERO_ADDRESS,
  tokenName: 'Ether',
  tokenSymbol: 'ETH',
  tokenDecimals: (0, Web3JSUtils_1.toBN)(18)
};
class AmountRequired {
  constructor(name, requiredValue, requiredTokenAddress, logger, listener, tokenMetadata = ether) {
    this._currentValue = (0, Web3JSUtils_1.toBN)(0);
    this._requiredValue = (0, Web3JSUtils_1.toBN)(0);
    this._currentTokenAddress = Constants_1.constants.ZERO_ADDRESS;
    this.logger = logger;
    this._name = name;
    this._tokenMetadata = tokenMetadata;
    this._requiredValue = requiredValue;
    this._listener = listener;
  }
  get currentValue() {
    return this._currentValue;
  }
  set currentValue(newValue) {
    const didChange = !this._currentValue.eq(newValue);
    const wasSatisfied = this.isSatisfied;
    this._currentValue = newValue;
    if (didChange) {
      this._onChange(wasSatisfied);
    }
  }
  get currentTokenAddress() {
    return this._currentTokenAddress;
  }
  set currentTokenAddress(newValue) {
    const didChange = !(0, Utils_1.isSameAddress)(this._currentTokenAddress, newValue);
    const wasSatisfied = this.isSatisfied;
    this._currentTokenAddress = newValue;
    if (didChange) {
      this._onChange(wasSatisfied);
    }
  }
  get requiredValue() {
    return this._requiredValue;
  }
  set requiredValue(newValue) {
    const didChange = !this._requiredValue.eq(newValue);
    const wasSatisfied = this.isSatisfied;
    this._requiredValue = newValue;
    if (didChange) {
      this._onChange(wasSatisfied);
    }
  }
  _onChange(wasSatisfied) {
    let changeString;
    if (wasSatisfied === this.isSatisfied) {
      changeString = `still${this.isSatisfied ? '' : ' not'}`;
    } else if (this.isSatisfied) {
      changeString = 'now';
    } else {
      changeString = 'no longer';
    }
    const message = `${this._name} requirement is ${changeString} satisfied\n${this.description}`;
    this.logger.warn(message);
    if (this._listener != null) {
      this._listener();
    }
  }
  get isSatisfied() {
    const correctTokenSatisfied = (0, Utils_1.isSameAddress)(this._tokenMetadata.tokenAddress, this._currentTokenAddress);
    const valueSatisfied = this._currentValue.gte(this._requiredValue);
    return correctTokenSatisfied && valueSatisfied;
  }
  get description() {
    const status = (0, Utils_1.boolString)(this.isSatisfied);
    const actual = (0, Utils_1.formatTokenAmount)(this._currentValue, this._tokenMetadata.tokenDecimals, this._tokenMetadata.tokenAddress, this._tokenMetadata.tokenSymbol);
    const required = (0, Utils_1.formatTokenAmount)(this._requiredValue, this._tokenMetadata.tokenDecimals, this._tokenMetadata.tokenAddress, this._tokenMetadata.tokenSymbol);
    return `${this._name.padEnd(14)} | ${status.padEnd(14)} | actual: ${actual.padStart(16)} | required: ${required.padStart(16)}`;
  }
}
exports.AmountRequired = AmountRequired;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MainnetCalldataGasEstimation = void 0;
const Utils_1 = require("../Utils");
/**
 * On the Ethereum Mainnet, the transaction cost is currently determined by the EIP-2028.
 * In case different coefficients are used later or in different chains, the values are read from the Environment.
 * @param calldata
 * @param environment
 * @constructor
 */
const MainnetCalldataGasEstimation = async (calldata, environment) => {
  const {
    calldataZeroBytes,
    calldataNonzeroBytes
  } = (0, Utils_1.calculateCalldataBytesZeroNonzero)(calldata);
  return environment.mintxgascost + calldataZeroBytes * environment.gtxdatazero + calldataNonzeroBytes * environment.gtxdatanonzero;
};
exports.MainnetCalldataGasEstimation = MainnetCalldataGasEstimation;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allStakeManagerEvents = exports.OwnerSet = exports.StakeWithdrawn = exports.StakeUnlocked = exports.StakePenalized = exports.StakeAdded = exports.HubUnauthorized = exports.HubAuthorized = exports.isInfoFromEvent = exports.ActiveManagerEvents = exports.Deposited = exports.TransactionRelayed = exports.TransactionRejectedByPaymaster = exports.RelayWorkersAdded = exports.RelayServerRegistered = exports.CommitAdded = void 0;
/** IPenalizer.sol */
exports.CommitAdded = 'CommitAdded';
/** IRelayHub.sol */
exports.RelayServerRegistered = 'RelayServerRegistered';
exports.RelayWorkersAdded = 'RelayWorkersAdded';
exports.TransactionRejectedByPaymaster = 'TransactionRejectedByPaymaster';
exports.TransactionRelayed = 'TransactionRelayed';
exports.Deposited = 'Deposited';
/**
 * Emitting any of these events is handled by GSN clients as a sign of activity by a RelayServer.
 */
exports.ActiveManagerEvents = [exports.RelayWorkersAdded, exports.TransactionRelayed, exports.TransactionRejectedByPaymaster];
function isInfoFromEvent(info) {
  return 'relayManager' in info;
}
exports.isInfoFromEvent = isInfoFromEvent;
/** IStakeManager.sol */
exports.HubAuthorized = 'HubAuthorized';
exports.HubUnauthorized = 'HubUnauthorized';
exports.StakeAdded = 'StakeAdded';
exports.StakePenalized = 'StakePenalized';
exports.StakeUnlocked = 'StakeUnlocked';
exports.StakeWithdrawn = 'StakeWithdrawn';
exports.OwnerSet = 'OwnerSet';
exports.allStakeManagerEvents = [exports.StakeAdded, exports.HubAuthorized, exports.HubUnauthorized, exports.StakeUnlocked, exports.StakeWithdrawn, exports.StakePenalized];
